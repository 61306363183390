import React from "react"

import { useCore } from "../../../hooks/useCore"

export const withPolicyNavigation = Component => ({ name = `PolicyNavigation`, location, navigation, title }) => {
  const {
    helpers: { isBrowser },
  } = useCore()

  const active = isBrowser ? navigation?.find(link => location?.pathname.includes(link.handle)) || navigation?.[0] : null

  Component.displayName = name
  return navigation?.length > 0 ? <Component active={active} navigation={navigation} title={title} /> : null
}
